import useCommon from '@/composables/useCommon'
import useFirestoreCollectionNames from '@/composables/useFirestoreCollectionNames'
import { computed, ref, watch } from '@vue/composition-api'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { filter, toLower } from 'lodash'
import moment from 'moment'
import useMoment from './../../../../composables/useMoment'
import { db } from '/src/firebase/config'

export default function useEnquiriesList() {
  const { enquiriesCollectionName } = useFirestoreCollectionNames()

  const { getCount, createOptions, stringContainsAny } = useCommon()
  const enquiriesListTable = ref([])
  const filteredEnquiriesListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'srno', sortable: false, align: 'center' },
    { text: 'Enquiry No', value: 'enq_id' },
    { text: 'Name', value: 'fullName' },
    { text: 'Type', value: 'etype' },
    { text: 'City', value: 'city' },
    { text: 'Email', value: 'email' },
    { text: 'Comments', value: 'comments' },
    { text: 'Enquiry Date', value: 'createdon' },
  ]

  const dateOptions = ['Today', 'Last 3 Days', 'Last 7 Days', 'Last Month', 'Custom']
  const dateFilter = ref('Last 3 Days')

  const searchQuery = ref('')
  const typeFilter = ref(null)
  const loading = ref(false)
  let unsubscribe = null
  const options = ref({
    sortBy: ['createdon'],
    sortDesc: [true],
  })
  const dateRange1 = ref([])

  const typeOptions = computed(() => {
    const mappedValuesWithCount = getCount(enquiriesListTable.value, 'etype')

    if (mappedValuesWithCount['Contact']) {
      mappedValuesWithCount['Contact'] = mappedValuesWithCount['Contact'] + (mappedValuesWithCount['contact'] || 0)
    } else if (mappedValuesWithCount['contact']) {
      mappedValuesWithCount['Contact'] = mappedValuesWithCount['contact']
    }
    delete mappedValuesWithCount['contact']

    if (mappedValuesWithCount['Dubai']) {
      mappedValuesWithCount['Dubai'] = mappedValuesWithCount['Dubai'] + (mappedValuesWithCount['dubai'] || 0)
    } else if (mappedValuesWithCount['dubai']) {
      mappedValuesWithCount['Dubai'] = mappedValuesWithCount['dubai']
    }
    delete mappedValuesWithCount['dubai']

    return createOptions(mappedValuesWithCount)
  })

  const queryOptions = () => {
    const qOptions = []

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'createdon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy('etype', 'asc'))
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchEnquiries = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      enquiriesListTable.value = []
      filteredEnquiriesListTable.value = []

      const q = query(collection(db, enquiriesCollectionName.value), ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        enquiriesListTable.value = []
        querySnapshot.forEach(doc => {
          enquiriesListTable.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredEnquiriesListTable.value = enquiriesListTable.value
        filterEnquiries()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const filterEnquiries = () => {
    let filteredEnquiries = enquiriesListTable.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredEnquiries = filter(filteredEnquiries, ({ fullName, email, city, comments, etype, phone }) => {
        return stringContainsAny(fullName + email + city + comments + etype + phone, searchQuery.value)
      })
    }

    if (typeFilter.value && typeFilter.value.length) {
      filteredEnquiries = filter(filteredEnquiries, ({ etype }) => {
        return toLower(typeFilter.value) == toLower(etype)
      })
    }

    if (dateRange1.value && dateRange1.value.length) {
      const { isBetweenDays, isSameDay } = useMoment()
      const [startDate, endDate] = dateRange1.value
      filteredEnquiries = filter(filteredEnquiries, ({ createdon }) => {
        if (endDate) {
          return isBetweenDays(createdon.toDate(), startDate, endDate)
        } else {
          return isSameDay(createdon.toDate(), startDate)
        }
      })
    }

    filteredEnquiriesListTable.value = filteredEnquiries
  }

  watch([searchQuery, typeFilter], () => {
    filterEnquiries()
  })

  watch([dateRange1], () => {
    if (dateRange1.value && dateRange1.value.length == 2) {
      const { isDateAfter } = useMoment()
      if (isDateAfter(dateRange1.value[0], dateRange1.value[1])) {
        ;[dateRange1.value[0], dateRange1.value[1]] = [dateRange1.value[1], dateRange1.value[0]]
      }
    }
    filterEnquiries()
  })

  watch(
    [dateFilter],
    () => {
      dateRange1.value = []
      const startDate = moment().format('YYYY-MM-DD')
      switch (dateFilter.value) {
        case 'Today':
          dateRange1.value = [startDate]
          break
        case 'Last 3 Days':
          dateRange1.value = [startDate, moment().subtract(3, 'd').format('YYYY-MM-DD')]
          break
        case 'Last 7 Days':
          dateRange1.value = [startDate, moment().subtract(7, 'd').format('YYYY-MM-DD')]
          break
        case 'Last Month':
          dateRange1.value = [startDate, moment().subtract(30, 'd').format('YYYY-MM-DD')]
          break
      }
    },
    { immediate: true },
  )

  const unsubscribeEnquiries = () => {
    unsubscribe()
  }

  fetchEnquiries()

  return {
    enquiriesListTable,
    filteredEnquiriesListTable,
    tableColumns,
    searchQuery,
    loading,
    options,
    typeOptions,
    typeFilter,
    dateOptions,
    dateFilter,
    dateRange1,

    unsubscribeEnquiries,
  }
}
