<template>
  <v-card @click="$router.push({ name: 'page-customers' })">
    <v-card-title class="align-start">
      <span>New Customers</span>
      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-if="customers.length > 0">
      <v-list class="pt-0 mt-n1">
        <v-list-item
          v-for="(customer, index) in customers"
          :key="index"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3':''}`"
        >
          <v-list-item-avatar size="38" color="primary" class="v-avatar-light-bg primary--text">
            <v-img v-if="customer.imageurl" :src="customer.imageurl"></v-img>
            <span v-else class="font-weight-semibold">{{ avatarText(customer.fullname) }}</span>
          </v-list-item-avatar>

          <div class="d-flex align-center flex-wrap text-no-wrap flex-grow-1">
            <div>
              <v-list-item-title class="text-sm font-weight-medium">
                {{ customer.fullname }}
              </v-list-item-title>
              <div class="d-flex align-center">
                <v-icon
                  size="14"
                  class="me-1"
                >
                  {{ icons.mdiCalendarBlankOutline }}
                </v-icon>
                <v-list-item-subtitle class="text-xs">
                  {{ formatDate(customer.createdon.toDate(), 'DD-MMM hh:mm:ss A') }}
                </v-list-item-subtitle>
              </div>
            </div>

            <v-spacer></v-spacer>

            <v-chip
              small
              color="primary"
              :class="`v-chip-light-bg primary--text font-weight-semibold mt-1`"
            >
              {{ customer.city }}
            </v-chip>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text v-else>
      <v-skeleton-loader v-for="i in 10" :key="i" type="list-item-avatar-two-line"></v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCalendarBlankOutline, mdiDotsVertical } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import useMoment from '@/composables/useMoment'

export default {
  props: {
    customers: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const avatar = require('@/assets/images/avatars/3.png')
    const { formatDate } = useMoment()

    return {
      avatar,
      avatarText,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiCalendarBlankOutline,
      },
    }
  },
}
</script>
