import moment from 'moment'

export default function useMoment() {
  const isBetweenDays = (date, startDate, endDate, format = 'YYYY-MM-DD') => {
    return moment(date, format).isBetween(startDate, endDate, 'days', '[]')
  }

  const isSameDay = (date, selectedDate, format = 'YYYY-MM-DD') => {
    return moment(date, format).isSame(selectedDate, 'day')
  }

  const formatDate = (date, format) => {
    return moment(date).format(format)
  }

  const isDateAfter = (date, selectedDate) => {
    return moment(date).isAfter(selectedDate)
  }

  return {
    isBetweenDays,
    isSameDay,
    formatDate,
    isDateAfter,
  }
}
