// import useMoment from './../../../../composables/useMoment'
import useCommon from '@/composables/useCommon'
import useMoment from '@/composables/useMoment'
import { computed, ref, watch } from '@vue/composition-api'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { filter, find } from 'lodash'
import { db } from '/src/firebase/config'

export default function useUsersList() {
  const { getOptions, stringContainsAny } = useCommon()
  const customersListTable = ref([])
  const filteredCustomersListTable = ref([])
  const typeFilter = ref(null)
  const locationFilter = ref(null)
  const platformFilter = ref(null)
  const resportDownload = ref(false)
  const locationOptions = computed(() => {
    return getOptions(customersListTable.value, 'city')
  })

  const platformOptions = computed(() => {
    const options = getOptions(customersListTable.value, 'platformtype')
    const found = find(options, { value: 'undefined' })
    if (found) {
      found.value = 'Website'
      found.title = found.title.replace('undefined', 'Website')
    }
    return options
  })

  const tableColumns = [
    { text: '#', value: 'srno', sortable: false, align: 'center' },
    { text: 'Full Name', value: 'fullname' },
    { text: 'City', value: 'city' },
    { text: 'Phone', value: 'phone' },
    { text: 'Type', value: 'type', align: 'center', sortable: false },
    { text: 'Created on', value: 'createdon' },
    { text: 'Actions', value: 'actions', align: 'center', sortable: false },
  ]

  const searchQuery = ref('')
  const loading = ref(false)
  let unsubscribe = null
  const options = ref({
    sortBy: ['createdon'],
    sortDesc: [true],
  })
  const dateRange = ref([])

  const queryOptions = () => {
    const qOptions = []

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'createdon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchCustomers = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      customersListTable.value = []
      filteredCustomersListTable.value = []

      const q = query(collection(db, 'customer'), ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        customersListTable.value = []
        querySnapshot.forEach(doc => {
          customersListTable.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredCustomersListTable.value = customersListTable.value
        filterCustomers()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const filterCustomers = () => {
    let filteredSubscriptions = customersListTable.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredSubscriptions = filter(filteredSubscriptions, ({ email, fullname, lname, city, phone }) => {
        return stringContainsAny(email + fullname + lname + city + phone, searchQuery.value)
      })
    }

    if (locationFilter.value && locationFilter.value.length) {
      filteredSubscriptions = filter(filteredSubscriptions, ({ city }) => {
        return locationFilter.value == city
      })
    }

    if (platformFilter.value && platformFilter.value.length) {
      filteredSubscriptions = filter(filteredSubscriptions, ({ platformtype }) => {
        if (platformFilter.value == 'Website') {
          return !platformtype
        } else {
          return platformFilter.value == platformtype
        }
      })
    }

    if (dateRange.value && dateRange.value.length) {
      const { isBetweenDays, isSameDay } = useMoment()
      const [startDate, endDate] = dateRange.value
      filteredSubscriptions = filter(filteredSubscriptions, ({ createdon }) => {
        if (endDate) {
          return isBetweenDays(createdon.toDate(), startDate, endDate)
        } else {
          return isSameDay(createdon.toDate(), startDate)
        }
      })
    }

    if (typeFilter.value && typeFilter.value.length) {
      filteredSubscriptions = filter(filteredSubscriptions, ({ isclubmember, membertype }) => {
        if (typeFilter.value == 'Privé') {
          return !!isclubmember
        } else if (typeFilter.value == 'PQ') {
          return membertype == 'PQ'
        } else {
          return false
        }
      })
    }

    filteredCustomersListTable.value = filteredSubscriptions
  }

  watch([searchQuery, typeFilter, locationFilter, platformFilter], () => {
    filterCustomers()
  })

  watch([dateRange], () => {
    if (dateRange.value && dateRange.value.length == 2) {
      const { isDateAfter } = useMoment()
      if (isDateAfter(dateRange.value[0], dateRange.value[1])) {
        ;[dateRange.value[0], dateRange.value[1]] = [dateRange.value[1], dateRange.value[0]]
      }
    }
    filterCustomers()
  })

  const unsubscribeCustomers = () => {
    unsubscribe()
  }

  const reesportDownloadFlag =async()=>{
    const q = doc(db, 'remoteconfig','notifications');
    const querySnapshot = await getDoc(q)
    if (querySnapshot.exists()) {
      resportDownload.value =  querySnapshot.data().jarvis_report_download;
    }
  } 

  fetchCustomers()
  reesportDownloadFlag();

  return {
    customersListTable,
    filteredCustomersListTable,
    typeFilter,
    tableColumns,
    searchQuery,
    loading,
    options,
    dateRange,
    locationFilter,
    locationOptions,
    platformOptions,
    platformFilter,

    unsubscribeCustomers,
    resportDownload
  }
}
