<template>
  <div>
    <v-row>
      <v-col>
        <DashboardStatisticsCard :bookings="bookingStats" :customers="customerStats" :enquiries="enquiryStats" :subscriptions="subscriptionStats" />
      </v-col>,
    </v-row>
    <v-row>
      <v-col>
        <DashboardBookingTimeline :bookings="newBookings" />
      </v-col>
      <v-col>
        <DashboardCustomersCard :customers="newCustomers" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import StatisticsCardAreaChart from '@core/components/statistics-card/StatisticsCardAreaChart.vue'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

import DashboardStatisticsCard from '@/components/DashboardStatisticsCard.vue'
import DashboardBookingTimeline from '@/components/DashboardBookingTimeline.vue'
import DashboardCustomersCard from '@/components/DashboardCustomersCard.vue'
import useCarBookings from '@/views/bookings/carbookings/useCarBookings'
import useCustomers from '@/views/customers/useCustomers'
import useEnquiriesList from '@/views/transactions/others/enquiries/useEnquiriesList'
import useSubscriptionsList from '@/views/transactions/others/subscriptions/useSubscriptionsList'
import useMoment from '@/composables/useMoment'

// icons
import { mdiLabelVariantOutline } from '@mdi/js'

// demos
import CrmStatisticsCard from './CrmStatisticsCard.vue'
import CrmTotalSales from './CrmTotalSales.vue'
import CrmRevenueReport from './CrmRevenueReport.vue'
import CrmSalesOverview from './CrmSalesOverview.vue'
import CrmActivityTimeline from './CrmActivityTimeline.vue'
import CrmWeeklySales from './CrmWeeklySales.vue'
import CrmCardUpgradePlan from './CrmCardUpgradePlan.vue'
import CrmCardMeetingSchedule from './CrmCardMeetingSchedule.vue'
import CrmCardDeveloperMeetup from './CrmCardDeveloperMeetup.vue'
import { computed, onUnmounted } from '@vue/composition-api'
import { filter } from 'lodash'
import moment from 'moment'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  components: {
    DashboardStatisticsCard,
    DashboardBookingTimeline,
    DashboardCustomersCard,
    StatisticsCardWithImages,
    StatisticsCardAreaChart,
    StatisticsCardVertical,

    CrmStatisticsCard,
    CrmTotalSales,
    CrmRevenueReport,
    CrmSalesOverview,
    CrmActivityTimeline,
    CrmWeeklySales,
    CrmCardUpgradePlan,
    CrmCardMeetingSchedule,
    CrmCardDeveloperMeetup,
  },
  setup() {
    const { isSameDay } = useMoment()
    const { country } = useAppConfig()

    const {
      carBookingList,

      unsubscribeCarBookings,
    } = useCarBookings(country.value)

    const bookingStats = computed(() => {
      const doneBookings = filter(carBookingList.value, ({ status }) => {
        return status == 'Done'
      })
      return {
        total: doneBookings.length,
        newToday: filter(doneBookings, ({ createdon }) => {
          return createdon && isSameDay(createdon.toDate(), moment().format('YYYY-MM-DD'))
        }).length,
      }
    })

    const {
      customersListTable,

      unsubscribeCustomers,
    } = useCustomers()

    const customerStats = computed(() => {
      return {
        total: customersListTable.value.length,
        newToday: filter(customersListTable.value, ({ createdon }) => {
          return isSameDay(createdon.toDate(), moment().format('YYYY-MM-DD'))
        }).length,
      }
    })

    const {
      enquiriesListTable,

      unsubscribeEnquiries,
    } = useEnquiriesList()

    const enquiryStats = computed(() => {
      return {
        total: enquiriesListTable.value.length,
        newToday: filter(enquiriesListTable.value, ({ createdon }) => {
          return isSameDay(createdon.toDate(), moment().format('YYYY-MM-DD'))
        }).length,
      }
    })

    const {
      subscriptionListTable,

      unsubscribeSubscriptions,
    } = useSubscriptionsList()

    const subscriptionStats = computed(() => {
      return {
        total: subscriptionListTable.value.length,
        newToday: filter(subscriptionListTable.value, ({ createdon }) => {
          return isSameDay(createdon.toDate(), moment().format('YYYY-MM-DD'))
        }).length,
      }
    })

    const newBookings = computed(() => {
      const doneBookings = filter(carBookingList.value, ({ status }) => {
        return status == 'Done'
      })
      return doneBookings.slice(0, 10)
    })

    const newCustomers = computed(() => {
      return customersListTable.value.slice(0, 10)
    })

    onUnmounted(() => {
      unsubscribeCarBookings()
      unsubscribeCustomers()
      unsubscribeEnquiries()
      unsubscribeSubscriptions()
    })

    return {
      bookingStats,
      customerStats,
      enquiryStats,
      subscriptionStats,
      newBookings,
      newCustomers,
    }
  },
}
</script>
