<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base">Statistics</span>
    </v-card-title>

    <v-card-text v-if="bookings.total > 0">
      <v-row>
        <v-col class="statistics-item" cols="6" md="3" @click="$router.push({ name: 'bookings-car' })">
          <div class="d-flex">
            <v-avatar size="60" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="45">
                {{ icons.mdiTrendingUp }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Bookings</p>
              <span class="text--primary text-xl font-weight-bold">{{ bookings.total }}</span> <br />
              <small class="text--primary">{{ bookings.newToday }} today</small>
            </div>
          </div>
        </v-col>
        <v-col class="statistics-item" cols="6" md="3" @click="$router.push({ name: 'page-customers' })">
          <div class="d-flex">
            <v-avatar size="60" color="success" rounded class="elevation-1">
              <v-icon dark color="white" size="45">
                {{ icons.mdiAccountOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Customers</p>
              <span class="text--primary text-xl font-weight-bold">{{ customers.total }}</span> <br />
              <small class="text--primary">{{ customers.newToday }} today</small>
            </div>
          </div>
        </v-col>
        <v-col class="statistics-item" cols="6" md="3" @click="$router.push({ name: 'others-enquiries' })">
          <div class="d-flex">
            <v-avatar size="60" color="warning" rounded class="elevation-1">
              <v-icon dark color="white" size="45">
                {{ icons.mdiEmailOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Enquiries</p>
              <span class="text--primary text-xl font-weight-bold">{{ enquiries.total }}</span> <br />
              <small class="text--primary">{{ enquiries.newToday }} today</small>
            </div>
          </div>
        </v-col>
        <v-col class="statistics-item" cols="6" md="3" @click="$router.push({ name: 'others-subscriptions' })">
          <div class="d-flex">
            <v-avatar size="60" color="info" rounded class="elevation-1">
              <v-icon dark color="white" size="45">
                {{ icons.mdiBellOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Subscriptions</p>
              <span class="text--primary text-xl font-weight-bold">{{ subscriptions.total }}</span> <br />
              <small class="text--primary">{{ subscriptions.newToday }} today</small>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-row>
        <v-col>
          <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiBellOutline, mdiTrendingUp, mdiDotsVertical, mdiEmailOutline } from '@mdi/js'

export default {
  props: {
    bookings: {
      type: Object,
      required: true,
    },
    customers: {
      type: Object,
      required: true,
    },
    enquiries: {
      type: Object,
      required: true,
    },
    subscriptions: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiEmailOutline,
        mdiBellOutline,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.statistics {
  &-item:hover {
    cursor: pointer;
  }
}
</style>
