<template>
  <v-card @click="$router.push({ name: 'bookings-car' })">
    <v-card-title class="align-start">
      <span>New Bookings</span>

      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-if="bookings.length > 0">
      <v-timeline dense class="timeline-custom-dense timeline-custom-dots">
        <v-timeline-item small :color="booking.type == 'Self Drive' ? 'primary' : 'success' " v-for="(booking, b) in bookings" :key="b">
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              {{ booking.type == 'Self Drive' ? 'SD' : 'CD' }} - {{ booking.customer_name }}, {{ booking.location }}
            </h4>
            <small class="text-no-wrap">
              {{ formatDate(booking.createdon.toDate(), 'DD-MMM hh:mm:ss A') }}
            </small>
          </div>
          <p class="mb-0">
            Booking ID: {{ booking.booking_id }}
            ({{ booking.type == 'Self Drive' ? 'for ' + booking.days + (booking.days > 1 ? ' days' : ' day') : booking.package }})
          </p>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>

    <v-card-text v-else>
      <v-skeleton-loader v-for="i in 10" :key="i" type="list-item-two-line"></v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'
import useMoment from '@/composables/useMoment'

export default {
  props: {
    bookings: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { formatDate } = useMoment()
    return {
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
    }
  },
}
</script>
