import useCommon from '@/composables/useCommon'
import useFirestoreCollectionNames from '@/composables/useFirestoreCollectionNames'
import useMoment from '@/composables/useMoment'
import { async } from '@firebase/util'
import { computed, ref, watch } from '@vue/composition-api'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
  orderBy
} from 'firebase/firestore'
import { concat, filter, find, isNil, orderBy as sortOrder, sortBy as sortArray } from 'lodash'
import moment from 'moment'
import { auth, db } from '/src/firebase/config'

export default function useCarBookings(country = 'India') {
  const { selfDriveBookingsCollectionName } = useFirestoreCollectionNames()

  const { getOptions, getCount, createOptions, stringContainsAny } = useCommon()
  const selfDriveBookingsList = ref([])
  const chauffeurDriveBookingsList = ref([])
  const filteredCarBookingsList = ref([])
  const resportDownload = ref(false);
  const locations = ref([])

  const carBookingList = computed(() => {

    return sortOrder(concat(selfDriveBookingsList.value, chauffeurDriveBookingsList.value), ['booking_id'], ['desc'])
  })

  const locationOptions = computed(() => {
    return getOptions(carBookingList.value, 'location')
  })

  const vendorOptions = computed(() => {
    const options = getOptions(filteredCarBookingsList.value, 'agentname')
    const found = find(options, { value: 'undefined' })
    if (found) {
      found.value = 'Not Assigned'
      found.title = found.title.replace('undefined', 'Not Assigned')
    }
    return sortArray(options, ['title'])
  })

  const packageOptions = computed(() => {
    const selfDriveOptions = [
      {
        title: `Self Drive (${selfDriveBookingsList.value.length})`,
        value: 'Self Drive',
      },
    ]
    const chauffeurDriveOptions = getOptions(chauffeurDriveBookingsList.value, 'package')
    return sortArray(concat(selfDriveOptions, chauffeurDriveOptions), ['title'])
  })

  const platformOptions = computed(() => {
    const options = getOptions(carBookingList.value, 'mplatform')
    const found = find(options, { value: 'undefined' })
    if (found) {
      found.value = 'Website'
      found.title = found.title.replace('undefined', 'Website')
    }
    return options
  })

  const typeOptions = computed(() => {
    const mappedValuesWithCount = getCount(carBookingList.value, 'pendingtype')
    mappedValuesWithCount['User'] =
      mappedValuesWithCount['UPI MWeb'] + mappedValuesWithCount['UPI Web'] + mappedValuesWithCount['undefined']
    delete mappedValuesWithCount['UPI MWeb']
    delete mappedValuesWithCount['UPI Web']
    delete mappedValuesWithCount['undefined']
    mappedValuesWithCount['PQ'] = filter(carBookingList.value, 'isamexused').length
    return createOptions(mappedValuesWithCount)
    console.log(mappedValuesWithCount,'mappedValuesWithCount')
  })

  const tableColumns = [
    { text: '#', value: 'srno', sortable: false, align: 'center' },
    { text: 'Booking', value: 'booking_id', width: '10%' },
    { text: 'Customer', value: 'customer_name' },
    { text: 'Detail', value: 'location' },
    { text: 'Date', value: 'start_date' },
    { text: 'Amount', value: 'amount' },
    { text: 'Type', value: 'type', align: 'center' },
    { text: '', value: 'actions' },
  ]

  const travelDateOptions = ['Today', 'Last 3 Days', 'Last 7 Days', 'Last Month', 'Custom']
  const travelDateFilter = ref('')

  const searchQuery = ref('')
  const statusFilter = ref('Done')
  const typeFilter = ref(null)
  const locationFilter = ref(null)
  const vendorFilter = ref(null)
  const packageFilter = ref(null)
  const platformFilter = ref(null)
  const loading = ref(false)
  let unsubscribeSelfDrive = null
  let unsubscribeChauffeurDrive = null
  const options = ref({
    sortBy: [],
    sortDesc: [],
  })
  const dateRange = ref([])
  const dateRange1 = ref([])

  const queryOptions = () => {
    const qOptions = []

    return qOptions
  }

  // fetch data
  const fetchSelfDriveBookings = async () => {
    try {
      if (unsubscribeSelfDrive) {
        unsubscribeSelfDrive()
      }
      loading.value = true

      const q = query(collection(db, selfDriveBookingsCollectionName.value), ...queryOptions())
      unsubscribeSelfDrive = onSnapshot(q, querySnapshot => {
        const sdBookingList = []
        querySnapshot.forEach(doc => {
          sdBookingList.push({
            id: doc.id,
            type: 'Self Drive',
            ...doc.data(),
          })
        })
        selfDriveBookingsList.value = sdBookingList
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const fetchChauffeurDriveBookings = async () => {
    try {
      if (unsubscribeChauffeurDrive) {
        unsubscribeChauffeurDrive()
      }
      loading.value = true

      const q = query(collection(db, 'cdrive_booking'), ...queryOptions())
      unsubscribeChauffeurDrive = onSnapshot(q, querySnapshot => {
        const cdBookingsList = []
        querySnapshot.forEach(doc => {
          cdBookingsList.push({
            id: doc.id,
            type: 'Chauffeur',
            ...doc.data(),
          })
        })
        chauffeurDriveBookingsList.value = cdBookingsList
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const fetchCarBookings = async () => {
    await fetchSelfDriveBookings()
    if (country == 'India') {
      await fetchChauffeurDriveBookings()
    }
  }

  const filterCarBookings = () => {
    let filteredSubscriptions = carBookingList.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredSubscriptions = filter(
        filteredSubscriptions,
        ({ type, booking_id, customer_name, phone, location, agentname }) => {
          return stringContainsAny(type + booking_id + customer_name + phone + location + agentname, searchQuery.value)
        },
      )
    }

    if (!isNil(statusFilter.value)) {
   
        filteredSubscriptions = filter(filteredSubscriptions, ({ status, iscancelled }) => {
          if (statusFilter.value == 'Cancelled') {
            return iscancelled
          } else {
            return statusFilter.value == status
          }
        })
    }


    if(statusFilter.value == "Pending"){
      filteredSubscriptions = sortOrder(filteredSubscriptions, ['createdon'], ['desc'])
    }


    if (typeFilter.value && typeFilter.value.length) {
      filteredSubscriptions = filter(filteredSubscriptions, ({ isamexused, pendingtype }) => {
        if (typeFilter.value == 'PQ') {
          return isamexused
        } else if (typeFilter.value == 'User') {
          return !pendingtype || pendingtype == 'UPI MWeb' || pendingtype == 'UPI Web'
        } else {
          return typeFilter.value == pendingtype
        }
      })
    }

    if (locationFilter.value && locationFilter.value.length) {
      filteredSubscriptions = filter(filteredSubscriptions, ({ location }) => {
        return locationFilter.value == location
      })
    }

    if (vendorFilter.value && vendorFilter.value.length) {
      filteredSubscriptions = filter(filteredSubscriptions, ({ agentname }) => {
        if (vendorFilter.value == 'Not Assigned') {
          return !agentname
        } else {
          return vendorFilter.value == agentname
        }
      })
    }

    if (packageFilter.value && packageFilter.value.length) {
      if (packageFilter.value == 'Self Drive') {
        filteredSubscriptions = filter(filteredSubscriptions, ({ type }) => {
          return 'Self Drive' == type
        })
      } else {
        filteredSubscriptions = filter(filteredSubscriptions, ({ type }) => {
          return 'Chauffeur' == type
        })

        filteredSubscriptions = filter(filteredSubscriptions, ({ package: pkg }) => {
          return packageFilter.value == pkg
        })
      }
    }

    if (platformFilter.value && platformFilter.value.length) {
      filteredSubscriptions = filter(filteredSubscriptions, ({ mplatform }) => {
        if (platformFilter.value == 'Website') {
          return !mplatform
        } else {
          return platformFilter.value == mplatform
        }
      })
    }

    if (dateRange.value && dateRange.value.length) {
      const { isBetweenDays, isSameDay } = useMoment()
      const [startDate, endDate] = dateRange.value
      filteredSubscriptions = filter(filteredSubscriptions, ({ createdon }) => {
        if (endDate) {
          return isBetweenDays(createdon.toDate(), startDate, endDate)
        } else {
          return isSameDay(createdon.toDate(), startDate)
        }
      })
    }

    if (dateRange1.value && dateRange1.value.length) {
      const { isBetweenDays, isSameDay } = useMoment()
      const [startDate, endDate] = dateRange1.value
      filteredSubscriptions = filter(filteredSubscriptions, ({ createdon }, index) => {
        if (createdon) {
          if (endDate) {
            return isBetweenDays(createdon.toDate(), startDate, endDate)
          } else {
            return isSameDay(createdon.toDate(), startDate)
          }
        }
      })
    }

    filteredCarBookingsList.value = filteredSubscriptions
  }

  watch(
    [
      carBookingList,
      searchQuery,
      statusFilter,
      typeFilter,
      locationFilter,
      vendorFilter,
      packageFilter,
      platformFilter,
    ],
    () => {
      filterCarBookings()
    },
  )

  watch([dateRange], () => {
    if (dateRange.value && dateRange.value.length == 2) {
      const { isDateAfter } = useMoment()
      if (isDateAfter(dateRange.value[0], dateRange.value[1])) {
        ;[dateRange.value[0], dateRange.value[1]] = [dateRange.value[1], dateRange.value[0]]
      }
    }
    filterCarBookings()
  })

  watch([dateRange1], () => {
    if (dateRange1.value && dateRange1.value.length == 2) {
      const { isDateAfter } = useMoment()
      if (isDateAfter(dateRange1.value[0], dateRange1.value[1])) {
        ;[dateRange1.value[0], dateRange1.value[1]] = [dateRange1.value[1], dateRange1.value[0]]
      }
    }
    filterCarBookings()
  })

  watch(
    [travelDateFilter],
    () => {
      dateRange1.value = []
      const startDate = moment().format('YYYY-MM-DD')
      switch (travelDateFilter.value) {
        case 'Today':
          dateRange1.value = [startDate]
          break
        case 'Last 3 Days':
          dateRange1.value = [startDate, moment().subtract(3, 'd').format('YYYY-MM-DD')]
          break
        case 'Last 7 Days':
          dateRange1.value = [startDate, moment().subtract(7, 'd').format('YYYY-MM-DD')]
          break
        case 'Last Month':
          dateRange1.value = [startDate, moment().subtract(30, 'd').format('YYYY-MM-DD')]
          break
      }
    },
    { immediate: true },
  )

  const unsubscribeCarBookings = () => {
    unsubscribeSelfDrive()
    if (unsubscribeChauffeurDrive) {
      unsubscribeChauffeurDrive()
    }
  }

  const sendDriverDetailsToCustomer = async docData => {
    try {
      docData.createdon = serverTimestamp()
      docData.updatedon = serverTimestamp()
      await addDoc(collection(db, 'driver_transaction'), docData)

      const bookingCollection =
        docData.package == 'Self Drive' ? selfDriveBookingsCollectionName.value : 'cdrive_booking'

      const docReference = doc(db, bookingCollection, docData.mid)
      await updateDoc(docReference, {
        verified: false,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const confirmCarBooking = async (id, type) => {
    try {
      const bookingidsDocRef = doc(db, 'parameter', 'bookingids')
      const bookingidsDocSnap = await getDoc(bookingidsDocRef)
      if (bookingidsDocSnap.exists()) {
        const cntr = bookingidsDocSnap.data().cntr
        const newBookingID = cntr + 1
        if (cntr != 0) {
          await updateDoc(bookingidsDocRef, {
            cntr: newBookingID,
          })

          const bookingCollection = type == 'Self Drive' ? selfDriveBookingsCollectionName.value : 'cdrive_booking'

          const docReference = doc(db, bookingCollection, id)
          await updateDoc(docReference, {
            status: 'Done',
            instamojo_id: '',
            booking_type: 'Offline',
            booking_id: newBookingID,
            payment_status: 'Done',
            smssent: false,
            emailsent: false,
            agentemailsent: false,
            agentsmssent: false,
            zohosent: false,
            whatsappsent: false
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getCustomerData = async phone => {
    let customer = {}
    const q = query(collection(db, 'customer'), where('phone', '==', phone))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(doc => {
      customer = { id: doc.id, ...doc.data() }
    })
    return customer
  }

  const getDriverTransactions = async bookingid => {
    let transactions = []
    const q = query(collection(db, 'driver_transaction'), where('bookingid', '==', bookingid))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach(doc => {
      transactions.push({ id: doc.id, ...doc.data() })
    })
    return sortOrder(transactions, ['createdon'], ['desc'])
  }

  const getVendors = async (type, carFleetId) => {
    const collectionName = type == 'Self Drive' ? 'sdrive_car' : 'cdrive_car'
    const docRef = doc(db, collectionName, carFleetId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const agentOptions = []
      docSnap.data().agent_active.forEach((item, index) => {
        if (item) {
          agentOptions.push(docSnap.data().agent_name[index])
        }
      })
      return agentOptions
    }
    return []
  }

  const assignAgentToBooking = async (type, id, docData) => {
    const bookingCollection = type == 'Self Drive' ? selfDriveBookingsCollectionName.value : 'cdrive_booking'

    const docReference = doc(db, bookingCollection, id)
    await updateDoc(docReference, docData)
  }
  const cancelCarBooking = async (type, id, cancellation_reason, refund_amount, percent_deducted, sendConfirmationCancel) => {
    const bookingCollection = type == 'Self Drive' ? selfDriveBookingsCollectionName.value : 'cdrive_booking'
    const docReference = doc(db, bookingCollection, id)
    const cancelled_by = {
      name: auth.currentUser.displayName,
      email: auth.currentUser.email,
      type: 'Admin',
    }
    await updateDoc(docReference, {
      cancelled_by,
      cancellation_reason,
      cancelledon: serverTimestamp(),
      refund_amount,
      percent_deducted,
      iscancelled: true,
      agentsmssent: false,
      sendagentsms: sendConfirmationCancel
    })
  }

  const reesportDownloadFlag =async()=>{
    const q = doc(db, 'remoteconfig','notifications');
    const querySnapshot = await getDoc(q)
    if (querySnapshot.exists()) {
      resportDownload.value =  querySnapshot.data().jarvis_report_download;
    }
  }

  const fetchLocations = async () => {
    let q = query(collection(db, 'location'), orderBy('description'))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      locations.value.push(doc.data().description)
    })
  }

  fetchCarBookings();
  reesportDownloadFlag();
  fetchLocations();

  return {
    filteredCarBookingsList,
    carBookingList,
    selfDriveBookingsList,
    tableColumns,
    searchQuery,
    loading,
    options,
    dateRange,
    dateRange1,
    statusFilter,
    typeFilter,
    locationOptions,
    vendorOptions,
    typeOptions,
    locationFilter,
    vendorFilter,
    packageOptions,
    packageFilter,
    platformOptions,
    platformFilter,
    travelDateOptions,
    travelDateFilter,

    unsubscribeCarBookings,
    getCustomerData,
    sendDriverDetailsToCustomer,
    assignAgentToBooking,
    getDriverTransactions,
    confirmCarBooking,
    getVendors,
    cancelCarBooking,
    resportDownload,
    locations
  }
}
