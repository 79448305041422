import { each, map, sortBy, toLower, without } from 'lodash'

export default function useCommon() {
  const getOptions = (list, key) => {
    const mappedValuesWithCount = getCount(list, key)
    return createOptions(mappedValuesWithCount)
  }

  const getCount = (list, key) => {
    const mappedValues = map(list, key)
    return mappedValues.reduce((acc, cur) => (!acc[cur] ? (acc[cur] = 1) : acc[cur]++, acc), {})
  }

  const createOptions = mappedValuesWithCount => {
    const options = []
    each(mappedValuesWithCount, (value, key) => {
      options.push({
        title: `${key} (${value})`,
        value: key,
      })
    })
    return sortBy(options, ['title'])
  }

  const stringContainsAny = (lookupString, searchQuery) => {
    lookupString = toLower(lookupString)
    searchQuery = toLower(searchQuery)
    const keywords = without(searchQuery.split(' '), '')
    return keywords.some(k => lookupString.includes(k))
  }

  return {
    createOptions,
    getOptions,
    getCount,
    stringContainsAny,
  }
}
