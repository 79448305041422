import useAppConfig from '@core/@app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default function useFirestoreCollectionNames() {
  const collections = {
    India: {
      carBrand: 'brand',
      carFeature: 'carfeature',
      location: 'location',
      agents: 'agent',
      carFleet: 'carfleet',
      selfDriveCar: 'sdrive_car',
      chauffeurDriveCar: 'cdrive_car',
      selfDriveBookings: 'sdrive_booking',
      chauffeurDriveBookings: 'cdrive_booking',
      yachtBookings: 'yacht_booking',
      customers: 'customer',
      enquiries: 'enquiries',
      subscriptions: 'subscriptions',
      feedbacks: 'feedbacks',
    },
    UK: {
      carBrand: 'uk_brand',
      carFeature: 'uk_carfeature',
      location: 'uk_location',
      agents: 'uk_agent',
      carFleet: 'uk_carfleet',
      selfDriveCar: 'uk_sdrive_car',
      chauffeurDriveCar: 'cdrive_car',
      selfDriveBookings: 'uk_sdrive_booking',
      chauffeurDriveBookings: 'cdrive_booking',
      yachtBookings: 'yacht_booking',
      customers: 'customer',
      enquiries: 'uk_enquiries',
      subscriptions: 'uk_subscriptions',
      feedbacks: 'uk_feedbacks',
    },
    UAE: {
      carBrand: 'uae_brand',
      carFeature: 'uae_carfeature',
      location: 'uae_location',
      agents: 'uae_agent',
      carFleet: 'uae_carfleet',
      selfDriveCar: 'uae_sdrive_car',
      chauffeurDriveCar: 'cdrive_car',
      selfDriveBookings: 'uae_sdrive_booking',
      chauffeurDriveBookings: 'cdrive_booking',
      yachtBookings: 'yacht_booking',
      customers: 'customer',
      enquiries: 'uae_enquiries',
      subscriptions: 'uae_subscriptions',
      feedbacks: 'uae_feedbacks',
    },
  }

  const { country } = useAppConfig()

  const carBrandCollectionName = computed(() => {
    return collections[country.value]['carBrand']
  })

  const carFeatureCollectionName = computed(() => {
    return collections[country.value]['carFeature']
  })

  const locationCollectionName = computed(() => {
    return collections[country.value]['location']
  })

  const agentsCollectionName = computed(() => {
    return collections[country.value]['agents']
  })

  const carFleetCollectionName = computed(() => {
    return collections[country.value]['carFleet']
  })

  const selfDriveCarCollectionName = computed(() => {
    return collections[country.value]['selfDriveCar']
  })

  const chauffeurDriveCarCollectionName = computed(() => {
    return collections[country.value]['chauffeurDriveCar']
  })

  const selfDriveBookingsCollectionName = computed(() => {
    return collections[country.value]['selfDriveBookings']
  })

  const chauffeurDriveBookingsCollectionName = computed(() => {
    return collections[country.value]['chauffeurDriveBookings']
  })

  const yachtBookingsCollectionName = computed(() => {
    return collections[country.value]['yachtBookings']
  })

  const customersCollectionName = computed(() => {
    return collections[country.value]['customers']
  })

  const enquiriesCollectionName = computed(() => {
    return collections[country.value]['enquiries']
  })

  const subscriptionsCollectionName = computed(() => {
    return collections[country.value]['subscriptions']
  })

  const feedbacksCollectionName = computed(() => {
    return collections[country.value]['feedbacks']
  })

  return {
    carBrandCollectionName,
    carFeatureCollectionName,
    locationCollectionName,
    agentsCollectionName,
    carFleetCollectionName,
    selfDriveCarCollectionName,
    chauffeurDriveCarCollectionName,
    selfDriveBookingsCollectionName,
    chauffeurDriveBookingsCollectionName,
    yachtBookingsCollectionName,
    customersCollectionName,
    enquiriesCollectionName,
    subscriptionsCollectionName,
    feedbacksCollectionName,
  }
}
